<template>
    <div>
        <v-progress-linear indeterminate absolute color="secondary" :active="carregando" />
        <v-simple-table v-if="usuarios.total > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th style="width: 20px;"></th>
                        <th>Usuario</th>
                        <th class="hidden-sm-and-down">E-mail</th>
                        <th class="hidden-sm-and-down">Grupo</th>
                        <th class="hidden-sm-and-down">Status</th>
                        <th v-if="filtro.id_franquia != undefined" style="width:30px;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(u, i) in usuarios.lista" :key="i">
                        <td class="pr-0">
                            <v-avatar size="40" v-if="u.imagem_perfil == 't'">
                                <img v-auth-image="`${apiUrl}usuario/${u.id_usuario}/imagem/thumb.jpg`"/>
                            </v-avatar>
                            <v-avatar size="40" v-else :color="corAvatar(u.usuario)">
                                <span class="white--text headline">{{u.usuario.substr(0, 1)}}</span>
                            </v-avatar>
                        </td>
                        <td>
                            <router-link exact color="primary" v-if="permissao('usuario_abrir')" :to="`/usuario/gerenciar/${u.id_usuario}`"><strong>{{u.usuario}}</strong></router-link>
                            <strong v-else>{{u.usuario}}</strong><br/>
                            <small><strong>ID:</strong> {{u.id_usuario}}</small>
                        </td>
                        <td class="hidden-sm-and-down"><a :href="`mailto:${u.email}`">{{u.email}}</a></td>
                        <td class="hidden-sm-and-down">
                            <router-link exact color="primary" v-if="permissao('usuario_grupo_abrir')" :to="`/usuario/grupo/gerenciar/${u.id_grupo}`">{{u.grupo}}</router-link>
                            <span v-else>{{u.grupo}}</span>
                        </td>
                        <td class="hidden-sm-and-down">
                            <v-chip small color="green" dark v-if="u.ativo === 't'"><v-icon left small>mdi-check-circle</v-icon> Ativo</v-chip>
                            <v-chip small color="red" dark v-else><v-icon left small>mdi-close-circle</v-icon> Inativo</v-chip>
                        </td>
                        <td v-if="filtro.id_franquia != undefined"><v-btn v-if="permissao('franquia_editar')" exact @click="relFranquiaUsuarioExcluir(u.id_usuario)" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-divider/>
        <Paginacao :disabled="carregando" @paginar="paginar" :total="usuarios.total"  />
    </div>
</template>

<script>

import axios from "axios"
import Paginacao from '@/Views/Estrutura/Paginacao'
import {mapState} from "vuex"

export default {
    name: "UsuarioListar",
    props : ['filtro'],
    components: {Paginacao},
    data() {
        return {
            carregando : false,
            usuarios : {
                lista : [],
                total : 0
            }
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'paginationLimit'])
    },
    methods : {
        // paginate
        paginar(pagina) {
            this.carregando = true
            return axios.post(`${this.baseUrl}usuario/listar`, {
                pagina,
                limit       : this.paginationLimit,
                offset      : this.paginationLimit * pagina,
                busca       : this.filtro.busca || null,
                id_grupo    : this.filtro.id_grupo || null,
                id_franquia : this.filtro.id_franquia || null
            }).then( (res) => {
                this.usuarios = res.data
                this.carregando = false
            })
        },
        relFranquiaUsuarioExcluir(id_usuario) {
            return axios.post(`${this.apiUrl}franquia/rel/usuario/excluir`, {
                id_usuario  : parseInt(id_usuario),
                id_franquia : parseInt(this.filtro.id_franquia)
            }).then( () => {
                this.paginar(0)
            })
        }
    },
    watch : {
        'filtro.busca' : function () {
            this.paginar(0)
        },
        'filtro.id_grupo' : function () {
            this.paginar(0)
        },
        'filtro.id_franquia' : function () {
            this.paginar(0)
        }
    },
    activated() {
        this.paginar(0)
    },
    created() {
        this.paginar(0)
    }
}
</script>

<style scoped>

</style>
